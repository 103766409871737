import React from 'react';
import './ContextMenu.css';

function ContextMenu({ x, y, options, onClose }) {
    return (
        <div className="contextMenu" style={{ top: y + 'px', left: x + 'px' }} onClick={onClose}>
            <ul>
                {options.map((option, index) => (
                    <li key={index} onClick={option.onClick}>
                        {option.label}
                    </li>
                ))}
            </ul>
        </div>
    );
}

export default ContextMenu;
