import React, { useState, useContext, useEffect } from "react";
import axios from "../services/axios";
import { UserContext } from "../contexts/userContext";
import { FaPlus, FaTrash } from "react-icons/fa";
import "./AddOrderForm.css";

function AddOrderForm({ onOrderAdded }) {
  const { resId } = useContext(UserContext); // Restaurant-specific data context

  const [customerName, setCustomerName] = useState("");
  const [customerPhone, setCustomerPhone] = useState("");
  const [tableNumber, setTableNumber] = useState("");
  const [orderItems, setOrderItems] = useState([{ itemId: "", itemName: "", quantity: 1, amount: 0, searchTerm: "", searchResults: [] }]);
  const [loading, setLoading] = useState(false);
  const [totalAmount, setTotalAmount] = useState(0);
  const [totalPayable, setTotalPayable] = useState(0);
  const [taxes, setTaxes] = useState(null);
  const [serviceCharge, setServiceCharge] = useState(null);
  const [paymentMode, setPaymentMode] = useState("post");

  // Calculate total amount, service charge amount, tax amount, and total payable
  useEffect(() => {
    const calculateTotals = () => {
      const total = orderItems.reduce((sum, item) => sum + (item.amount * item.quantity), 0);
      setTotalAmount(total);

      const serviceChargeAmount = serviceCharge ? (total * (serviceCharge / 100)) : 0;
      const taxAmount = taxes ? ((total + serviceChargeAmount) * (taxes / 100)) : 0;
      const totalPayable = total + serviceChargeAmount + taxAmount;

      setTotalPayable(Math.max(totalPayable, 0));

      return { serviceChargeAmount, taxAmount, totalPayable };
    };

    calculateTotals();
  }, [orderItems, serviceCharge, taxes]);

  // Fetch food items as the user types
  const searchFoodItems = async (index, query) => {
    if (query.length < 2) return;
    try {
      const response = await axios.get(`spadeleaf-api.php?action=searchFoodItem&resId=${resId}&searchQuery=${query}`);
      const newOrderItems = [...orderItems];
      newOrderItems[index].searchResults = response.data;
      setOrderItems(newOrderItems);
    } catch (error) {
      console.error("Error fetching food items:", error);
    }
  };

  // Handle search input change
  const handleSearchInputChange = (index, value) => {
    const newOrderItems = [...orderItems];
    newOrderItems[index].searchTerm = value;
    newOrderItems[index].itemName = value;
    setOrderItems(newOrderItems);
    searchFoodItems(index, value);
  };

  // Handle item selection from dropdown
  const handleSelectItem = (index, item) => {
    const newOrderItems = [...orderItems];
    newOrderItems[index] = { ...newOrderItems[index], itemId: item.itemId, itemName: item.itemName, quantity: 1, amount: item.itemPrice, searchTerm: "", searchResults: [] };
    setOrderItems(newOrderItems);
  };

  // Handle item change for each order
  const handleItemChange = (index, field, value) => {
    const newOrderItems = [...orderItems];
    newOrderItems[index][field] = value;
    setOrderItems(newOrderItems);
  };

  // Add a new item to the order
  const addNewItem = () => {
    setOrderItems([...orderItems, { itemId: "", itemName: "", quantity: 1, amount: 0, searchTerm: "", searchResults: [] }]);
  };

  // Remove an item from the order
  const removeItem = (index) => {
    const newOrderItems = orderItems.filter((_, i) => i !== index);
    setOrderItems(newOrderItems);
  };

  const handlePaymentModeChange = (e) => {
    const selectedMode = e.target.value === "paid" ? "pre" : "post";
    setPaymentMode(selectedMode);
  };

  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    const orderData = {
      customerName,
      customerPhone,
      items: orderItems.map(item => ({
        id: item.itemId,
        count: item.quantity,
        amount: item.amount,
      })),
      amount: totalPayable,
      taxes: taxes || 0,
      serviceCharge: serviceCharge || 0,
      mode: paymentMode,
      status: 0, // Set to 0 for ongoing orders
    };

    try {
      const response = await axios.post(`spadeleaf-api.php?action=placeOrder&resId=${resId}&table=${tableNumber}`, orderData);
      if (response.data.message === 1) {
        onOrderAdded();
        alert("Order added successfully!");
        setCustomerName("");
        setCustomerPhone("");
        setTableNumber("");
        setOrderItems([{ itemId: "", itemName: "", quantity: 1, amount: 0, searchTerm: "", searchResults: [] }]);
      } else {
        alert(response.data.message);
      }
    } catch (error) {
        console.error("Error adding order:", error);
    } finally {
        setLoading(false);
    }
  };

  useEffect(() => {
    async function fetchData() {
      try {
        const response = await axios("spadeleaf-api.php?action=getAdditionalCharges&resId=" + resId);
        if (response.data.serviceCharges) {
          setServiceCharge(response.data.serviceCharges);
        }
        if (response.data.taxes) {
          setTaxes(response.data.taxes);
        }
      } catch (error) {
        console.log(error);
        alert("Failed to fetch taxes. Kindly reload.")
      }
    }
    fetchData();
  }, [resId]);

  return (
    <div className="addOrderForm">
      <h3>Add New Order</h3>
      <form onSubmit={handleSubmit}>
        <div className="formGroup">
          <label>Customer Name:</label>
          <input
            type="text"
            value={customerName}
            onChange={(e) => setCustomerName(e.target.value)}
            placeholder="Enter customer name"
            required
          />
        </div>

        <div className="formGroup">
          <label>Customer Phone:</label>
          <input
            type="tel"
            value={customerPhone}
            onChange={(e) => setCustomerPhone(e.target.value)}
            placeholder="Enter customer phone"
            pattern="[0-9]{10}" // Simple phone validation
            required
          />
        </div>

        <div className="formGroup">
          <label>Table Number (optional):</label>
          <input
            type="number"
            value={tableNumber}
            onChange={(e) => setTableNumber(e.target.value)}
            placeholder="Enter table number"
          />
        </div>

        <h4>Order Items</h4>
        {orderItems.map((item, index) => (
          <div key={index} className="orderItem">
            <input
              type="text"
              placeholder="Item Name"
              value={item.itemName}
              onChange={(e) => handleSearchInputChange(index, e.target.value)}
              required
            />
            {item.searchTerm && item.searchResults.length > 0 && (
              <ul className="dropdown">
                {item.searchResults.map((result) => (
                  <li key={result.itemId} onClick={() => handleSelectItem(index, result)}>
                    {result.itemName} - ₹{result.itemPrice}
                  </li>
                ))}
              </ul>
            )}
            <input
              type="number"
              placeholder="Quantity"
              value={item.quantity}
              onChange={(e) => handleItemChange(index, "quantity", e.target.value)}
              required
            />
            <input
              type="number"
              placeholder="Amount (₹)"
              value={item.amount}
              readOnly
            />
            <button type="button" className="removeBtn" onClick={() => removeItem(index)}>
              <FaTrash />
            </button>
          </div>
        ))}
        <button type="button" className="addItemBtn" onClick={addNewItem}>
          <FaPlus /> Add Another Item
        </button>

        <div className="formGroup">
          <label>Payment Status:</label>
          <select value={paymentMode === "pre" ? "paid" : "unpaid"} onChange={handlePaymentModeChange} required>
            <option value="unpaid">Unpaid</option>
            <option value="paid">Paid</option>
          </select>
        </div>

        <div className="orderSummary">
          <p>Total Amount: ₹{totalAmount.toFixed(2)}</p>
          <p>Service Charge Amount: ₹{serviceCharge ? (totalAmount * (serviceCharge / 100)).toFixed(2) : "0.00"}</p>
          <p>Tax Amount: ₹{taxes ? ((totalAmount + (serviceCharge ? (totalAmount * (serviceCharge / 100)) : 0)) * (taxes / 100)).toFixed(2) : "0.00"}</p>
          <p>Total Payable: ₹{(totalAmount + (serviceCharge ? (totalAmount * (serviceCharge / 100)) : 0) + (taxes ? ((totalAmount + (serviceCharge ? (totalAmount * (serviceCharge / 100)) : 0)) * (taxes / 100)) : 0)).toFixed(2)}</p>
        </div>

        <button type="submit" className="submitBtn" disabled={loading}>
          {loading ? "Adding..." : "Add Order"}
        </button>
      </form>
    </div>
  );
}

export default AddOrderForm;