import React, {useState, useEffect, useRef, useContext} from "react";
import axios from "../services/axios";
import { UserContext } from '../contexts/userContext';
import ContextMenu from "./ContextMenu";

import "./MenuItem.css";

import ItemInsertModal from "./ItemInsertModal";

function MenuItem( {category} ) {
    const[foodItems, setFoodItems] = useState( [] );
    const [loading, setLoading] = useState(true);
    const [contextMenu, setContextMenu] = useState({ visible: false, x: 0, y: 0, categoryIndex: null });
    const { resId } = useContext(UserContext);
    const longPressTimeout = useRef(null);
    const [error, setError] = useState(null);

    const [isModalOpen, setIsModalOpen] = useState(false);

    const openModal = () => {
        setIsModalOpen(true);
    };

    const closeModal = () => {
        setIsModalOpen(false);
    };

    const handleContextMenu = (event, itemId) => {
        event.preventDefault();
        const rect = event.target.getBoundingClientRect();
        setContextMenu({
            visible: true,
            x: rect.left,
            y: window.scrollY, // Ensure it accounts for scroll position
            itemId: itemId
        });
    };

    const handleTouchStart = (event, itemId) => {
        longPressTimeout.current = setTimeout(() => {
            const rect = event.target.getBoundingClientRect();
            setContextMenu({
                visible: true,
                x: rect.left,
                y: window.scrollY, // Ensure it accounts for scroll position
                itemId: itemId
            });
        }, 500);
    };

    const handleTouchEnd = () => {
        clearTimeout(longPressTimeout.current);
    };

    const handleMenuClose = () => {
        setContextMenu({ ...contextMenu, visible: false });
    };

    async function fetchData() {
        try {
            const response = await axios("spadeleaf-api.php?action=getFoodItem&ItemCategory="+category);
            setFoodItems(response.data);
            setLoading(false);
          } catch (error) {
            console.log(error);
            setError("Failed to fetch food items. Please try again later.");
            setLoading(false);
          }
    }

    const handleItemChange = (itemId, propertyName, updatedValue) => {
        async function fetchData() {
            try {
                const response = await axios.post("spadeleaf-api.php?action=updateFoodItem", {
                    item: itemId,
                    property: propertyName,
                    value: updatedValue,
                  });
                if (response.data.message === 1)
                {
                    alert("Updated");
                }
                else
                {
                    alert(response.data.message);
                    console.log(response);
                }
              } catch (error) {
                console.log(error);
              }
        }
        fetchData();
    }

    const handleHideItem = async (itemId) => {
        try {
            const response = await axios.post("spadeleaf-api.php?action=hideFoodItem", {
                itemId: itemId,
                resId: resId,
            });
            if (response.data.message === 1) {
                fetchData();
                handleMenuClose();
            } else {
                alert(response.data.message);
                console.log(response);
            }
        } catch (error) {
            console.log(error);
        }
    };

    const handleDeleteItem = async (itemId) => {
        try {
            const response = await axios.post('spadeleaf-api.php?action=deleteFoodItem', { 
                itemId: itemId,
                resId: resId,
                categoryId: category, 
            });
            if (response.data.message === 1)
            {
                fetchData();
                handleMenuClose();
            }
            else
            {
                alert(response.data.message);
                console.log(response);
            }
        } catch (error) {
            console.error('Error deleting item:', error);
        }
        handleMenuClose();
    };

    useEffect( () =>{
        fetchData();
    }, [category, handleDeleteItem, handleHideItem]); 

    if (loading) {
        return <div>Loading...</div>; // or render a loading spinner
    }
    
    if (error) {
        return <div>{error}</div>; // or render an error message
    }

    const contextMenuOptions = [
        { label: 'Toggle Visibility', onClick: () => handleHideItem(contextMenu.itemId) },
        { label: 'Delete', onClick: () => handleDeleteItem(contextMenu.itemId) },
        // Add more options as needed
    ];

    return (
        <div className="menuItemContainer">

            <ItemInsertModal isOpen={isModalOpen} onClose={closeModal} category={category}/>

            <button className="addItemButton" onClick={openModal}>Add food</button>

            {contextMenu.visible && (
                <ContextMenu
                    x={contextMenu.x}
                    y={contextMenu.y}
                    options={contextMenuOptions}
                    onClose={handleMenuClose}
                />
            )}

            {foodItems.map(foodItem => (
                <div key={foodItem.ItemId} className="menuItem">
                    <div className="menuImageContainer">
                        <img
                            className="menuImage"
                            src={foodItem.ItemImage} alt={foodItem.ItemName}/>
                    </div>
                    <div className="menuItemDescription">
                    <h2
                        style={{ textDecoration: foodItem.IsHidden === '1' ? 'line-through' : 'none' }}
                        onContextMenu={(event) => handleContextMenu(event, foodItem.ItemId)}
                        onTouchStart={(event) => handleTouchStart(event, foodItem.ItemId)}
                        onTouchEnd={handleTouchEnd}
                        >
                            {foodItem.ItemName}
                        </h2>
                        <div className="priceContainer">
                            <h5>Price: ₹</h5>
                            <h5
                                contentEditable="true"
                                onBlur={(e) => {
                                    const updatedValue = e.target.innerText;
                                    handleItemChange(foodItem.ItemId, "ItemPrice", updatedValue);
                                }}
                            >
                                {foodItem.ItemPrice}
                            </h5>
                        </div>
                        <p
                            contentEditable="true"
                            onBlur={(e) => {
                                const updatedValue = e.target.innerText;
                                handleItemChange(foodItem.ItemId, "ItemDescription", updatedValue);
                            }}
                        >
                            {foodItem.ItemDescription}
                        </p>                                             
                    </div>
                </div>
            ))}

        </div>
    );
  }
  
  export default MenuItem;