import React, { useState, useEffect, useContext, useRef } from "react";
import axios from "../services/axios";
import { UserContext } from '../contexts/userContext';
import "./MenuCategories.css";
import MenuItem from "./MenuItem";
import CategoryInsertModal from "./CategoryInsertModal";
import ContextMenu from "./ContextMenu";

function MenuCategories() {
    const [foodCategories, setFoodCategories] = useState([]);
    const [activeCategory, setActiveCategory] = useState(0);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [contextMenu, setContextMenu] = useState({ visible: false, x: 0, y: 0, categoryIndex: null });
    const [editCategoryIndex, setEditCategoryIndex] = useState(null);
    const { resId } = useContext(UserContext);
    const longPressTimeout = useRef(null);

    const openModal = () => {
        setIsModalOpen(true);
    };

    const closeModal = () => {
        setIsModalOpen(false);
    };

    const handleLinkClick = (index) => {
        setActiveCategory(index);
    };

    const handleContextMenu = (event, index) => {
        event.preventDefault();
        const rect = event.target.getBoundingClientRect();
        setContextMenu({
            visible: true,
            x: rect.left,
            // y: rect.bottom + window.scrollY, // Ensure it accounts for scroll position
            categoryIndex: index
        });
    };

    const handleTouchStart = (event, index) => {
        longPressTimeout.current = setTimeout(() => {
            const rect = event.target.getBoundingClientRect();
            setContextMenu({
                visible: true,
                x: rect.left,
                // y: rect.bottom + window.scrollY, // Ensure it accounts for scroll position
                categoryIndex: index
            });
        }, 500);
    };

    const handleTouchEnd = () => {
        clearTimeout(longPressTimeout.current);
    };

    const handleMenuClose = () => {
        setContextMenu({ ...contextMenu, visible: false });
    };

    const handleEditCategory = (index) => {
        setEditCategoryIndex(index);
        handleMenuClose();
    };

    const handleHideCategory = (index) => {
        async function hideCategory() {
            try {
                const categoryId = foodCategories[index].categoryId;
                const response = await axios.post("spadeleaf-api.php?action=hideCategory", {
                    categoryId: categoryId,
                    resId: resId, 
                  });
                if (response.data.message === 1)
                {
                    fetchData();
                    handleMenuClose();
                }
                else
                {
                    alert(response.data.message);
                    console.log(response);
                }
              } catch (error) {
                console.log(error);
              }
        }
        hideCategory();
    };

    const handleDeleteCategory = async (index) => {
        try {
            const categoryId = foodCategories[index].categoryId;
            const response = await axios.post('spadeleaf-api.php?action=deleteFoodCategory', { 
                categoryId: categoryId,
                resId: resId, 
            });
            if (response.data.message === 1)
            {
                fetchData();
                handleMenuClose();
            }
            else
            {
                alert(response.data.message);
                console.log(response);
            }
        } catch (error) {
            console.error('Error deleting category:', error);
        }
        handleMenuClose();
    };

    const handleCategoryChange = (event, index) => {
        const newCategories = [...foodCategories];
        newCategories[index].category = event.target.value;
        setFoodCategories(newCategories);
    };

    const handleCategoryBlur = async (index) => {
        try {
            const categoryId = foodCategories[index].categoryId;
            const category = foodCategories[index].category;
            const response = await axios.post('spadeleaf-api.php?action=updateFoodCategory', {
                resId: resId,
                categoryId: categoryId,
                category: category 
            });
            if (response.data.message === 1)
            {
                fetchData();
                handleMenuClose();
            }
            else
            {
                alert(response.data.message);
                console.log(response);
            }
        } catch (error) {
            console.error('Error updating category:', error);
        }
        setEditCategoryIndex(null);
    };

    async function fetchData() {
        try {
            const response = await axios(`spadeleaf-api.php?action=getFoodCategory&resId=${resId}`);
            setFoodCategories(response.data);
        } catch (error) {
            console.error('Error fetching food categories:', error);
        }
    }

    useEffect(() => {
        fetchData();
    }, [resId]);

    if (foodCategories.length === 0 || activeCategory >= foodCategories.length) {
        return null;
    }

    const contextMenuOptions = [
        { label: 'Edit', onClick: () => handleEditCategory(contextMenu.categoryIndex) },
        { label: 'Toggle Visibility', onClick: () => handleHideCategory(contextMenu.categoryIndex) },
        { label: 'Delete', onClick: () => handleDeleteCategory(contextMenu.categoryIndex) },
        // Add more options as needed
    ];

    return (
        <div className="menuCategories" id="menuCategories" onClick={handleMenuClose}>
            <CategoryInsertModal isOpen={isModalOpen} onClose={closeModal} />

            {foodCategories.map((foodCategory, index) => (
                <span key={index} className="categoryWrapper">
                    <a
                        href="#menuCategories"
                        onClick={() => handleLinkClick(index)}
                        onContextMenu={(event) => handleContextMenu(event, index)}
                        onTouchStart={(event) => handleTouchStart(event, index)}
                        onTouchEnd={handleTouchEnd}
                        className={index === activeCategory ? "active" : "inactive"}
                        style={{ textDecoration: foodCategory.isHidden === "1" ? 'line-through' : 'none' }}
                    >
                        {editCategoryIndex === index ? (
                            <input
                                type="text"
                                value={foodCategory.category}
                                onChange={(event) => handleCategoryChange(event, index)}
                                onBlur={() => handleCategoryBlur(index)}
                                autoFocus
                            />
                        ) : (
                            foodCategory.category
                        )}
                    </a>
                    {" | "}
                </span>
            ))}

            <button className="addCategoryButton" onClick={openModal}>Add category</button>

            {contextMenu.visible && (
                <ContextMenu
                    x={contextMenu.x}
                    y={contextMenu.y}
                    options={contextMenuOptions}
                    onClose={handleMenuClose}
                />
            )}

            <MenuItem category={foodCategories[activeCategory].categoryId} />
        </div>
    );
}

export default MenuCategories;
