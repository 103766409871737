import React, { useState, useContext } from "react";
import { NavLink } from "react-router-dom";

import { UserContext } from '../contexts/userContext';

import './NavBar.css';

function NavBar() {
    const [navVisibility, setNavVisibility] = useState("hideNavBar");
    const{ resId, setResId } = useContext(UserContext);

    /* nav bar screen responsive animation has to be better  */
    const changeNavVisibility = () => {
        if (navVisibility === "hideNavBar") {
            setNavVisibility("showNavBar")
        }
        else {
            setNavVisibility("hideNavBar")
        } 

    }

    const handleLogout = () => {
        localStorage.clear();
         setResId('');
     }

    return (
        <nav className="navbar">
            <div className="container">
                <div className="hamburger-lines" onClick={changeNavVisibility}>
                    <span className="line line1"></span>
                    <span className="line line2"></span>
                    <span className="line line3"></span>
                </div>

                <div className="logo">
                    <a href="#" class="logo">
                        <img src="https://ik.imagekit.io/gycc9trp8/spadeleaf/common_assets/logo.png?updatedAt=1726236699956" alt="logo" />
                    </a>
                </div>
                <div className={"nav-elements " + navVisibility}>
                    <ul className="nav-items">
                        <li>
                            <NavLink to="/" end>Orders</NavLink>
                        </li>
                        <li>
                            <NavLink to="/menu">Menu</NavLink>
                        </li>
                        <li>
                            <NavLink to="/about">About</NavLink>
                        </li>
                        <li>
                            <NavLink to="/contact">Contact</NavLink>
                        </li>
                        <li>
                            <button onClick={() => handleLogout()}>Logout</button>
                        </li>
                    </ul>
                </div>         
            </div>
        </nav>
  )}
  
  export default NavBar;
  